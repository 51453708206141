@import 'theme.less';



.events-timeline-item {
  position: relative;
  padding: 5px;

  // display: grid;
  // grid-template-columns: 16px auto;
  margin-left: 20px;

  transition: 0.1s;
  margin-top: 0;
  animation: fromright 0.2s linear;

  @keyframes fromright {
    0% {
      margin-top: -100px;
    }

    100% {
      margin-top: 0;
    }
  }
}

.events-timeline-line {
  position: absolute;
  width: 2px;
  height: calc(100% - 14px);
  top: 29px;
  left: -5px;
  background-color: rgba(255, 255, 255, 0.3);
}

.events-time,
.events-time-atto {
  font-size: @font-size-small;
  text-align: center;
  font-weight: bold;
  opacity: 0.5;
  white-space: nowrap;
}

.events-state-icon {
  font-size: 14px;
  // padding-top: 22px;

  &[data-state='fail'],
  &[data-alert='true'] {
    color: @error-color;
  }

  &[data-state='pass'] {
    color: @success-color;
  }
}

.events-timeline-item-header {
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 14px;
  // align-items: center;
  line-height: 12px;
  margin: 10px 2px 6px;

  .events-time {
    line-height: 14px;
  }
}

.events-timeline-dot {
  position: absolute;
  text-align: center;
  left: -11px;
}

.events-timeline-camera {
  // display: inline-block;
  // line-height: 12px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  // max-width: 100%;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;


  & > .rs-icon {
    margin-right: 5px;
  }
}

.events-timeline-data {
  padding: 4px 2px 4px 4px;
  border-radius: @default-border-radius;
  background-color: @panel-bg-color;
  line-height: 0;

  display: grid;
  grid-template-columns: min-content auto;

  &.withimages {
    display: block;
  }
}

.events-timeline_status {
  min-height: 20px;
  position: relative;
  margin: 5px 2px 2px 2px;
  line-height: 14px;
  font-weight: bold;
  opacity: 0.8;

  &[data-state='fail'],
  &[data-alert='true'] {
    color: @error-color;
  }

  &[data-state='pass'] {
    color: @success-color;
  }
}

.events-timeline-date {
  position: relative;
  top: 12px;
  font-weight: bold;
  font-size: @font-size-small;
  opacity: 0.7;
  padding: 5px;
}

.events-timeline_images {
  // max-width: 106px;
  // margin-bottom: 4px;
}

.events-image-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 1px;
  z-index: 1;
}

.event-image-expanded {
  // min-height: 90%;
  text-align: center;

  .rs-modal-header {
    margin-bottom: 10px;
    padding-right: 0;
  }

  .rs-modal-body {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 100px;
    min-width: 100px;
    display: inline-block;
  }

  .camera-canvas-stat {
    top: 5px;
  }

  .camera-canvas-status {
    top: 5px;
  }

  .events-date-subtitle {
    margin-right: 10px;
    opacity: 0.6;
  }
}

.event-image-title {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
  white-space: nowrap;
}

.events-celltime {
  .events-date-subtitle {
    opacity: 0.5;
    margin-top: -4px;
    font-size: 90%;
  }
}

.event-image {
  min-width: 10vw;
  max-width: 100%;
  max-height: 80vh;

  display: block;
  margin: 0 auto;
}

.rs-notification-description {
  img {
    width: 100%;
  }
}

.events-data {
  overflow-x: hidden;
}

.events-tag-item {
  max-width: 100%;
  // min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  word-break: break-all;
  margin: 1px !important;
  padding: 3px 6px 4px !important;
  line-height: 15px !important;

  &[data-interaction='true'] {
    cursor: pointer;
  }
}

.item-icon {
  display: inline;
  position: relative;
  height: 12px;
  margin: 0 2px 0 -2px;
}

.events-filter {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 20px);
  border: none;
  background: none;

  &.rs-picker-check {
    min-height: 28px;

    .rs-picker-toggle {
      min-height: 28px;
      border: none;
      background: none !important;
    }
  }

  .rs-picker-value-list {
    width: 100%;
  }

  .rs-picker-toggle {
    left: 0;
    padding-right: 24px !important;
  }

  .rs-picker-tag-wrapper {
    padding-right: 25px !important;

    .rs-picker-search-input {
      text-align: center;
    }
  }

  .rs-picker-toggle-placeholder {
    display: none;
  }

  .rs-picker-toggle-value {
    text-align: center;
  }

  .rs-picker-toggle-caret {
    right: 0px !important;
  }

  .rs-picker-toggle-clean {
    right: 14px !important;
  }

  &.rs-picker-focused,
  &.rs-picker-has-value {
    & + .events-filter-label {
      opacity: 0;
    }
  }
}

.events-filter-label {
  text-align: center;
  width: 100%;
  transition: 0.2s;
  opacity: 1;
}

.eventsbar {
  position: fixed !important;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1040;
  background: @panel-bg-color;
  backdrop-filter: @blurfilter;
  // box-shadow: @default-shadow;
  transition: 0.2s;
  min-width: 240px;
  // max-width: 240px;

  & > .rs-sidebar {
    position: relative;
    height: 100%;
  }

  &[data-expand='true'] {
    & ~ .content {
      margin-right: 240px;

      .dndlayout {
        width: calc(100% + 20px);
      }
    }
  }

  &[data-expand='false'] {
    max-width: 0;
    min-width: 0;

    .togglebar-btn {
      left: -32px;
    }

    .menu-title {
      display: none;
    }

    & ~ .content {
      margin-right: 0px;

      .content-header {
        padding-right: 15px;
      }

    }
  }

  .eventsbar-header {
    padding: 10px;
    text-align: center;
    cursor: default;
  }

  .eventsbar-title {
    margin: 0;
    font-size: 16pt;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .eventsbar-content {
    top: -15px;
    height: calc(100% - 30px) !important;
  }

  .togglebar-btn {
    left: -12px;
  }

  .rs-content {
    position: relative;
    width: 100%;
  }

  .rs-tag {
    margin: 1px;
  }
}

.event-alert-title {
  color: @error-color;
}

.events-item-count {
  margin-right: 2px;
  font-weight: bold;
  display: inline-block;
}

.eventsarchive-export-list {
  .rs-dropdown-item {
    position: relative;
    min-width: 100px;
    padding-left: 34px;
    text-align: left;
    text-transform: uppercase;

    &.unselected-column {
      opacity: 0.5;
    }
  }

  .rs-dropdown-item-menu-icon {
    position: absolute;
    margin-top: 2px;
    left: 12px;
  }

  .rs-progress-line {
    position: relative;
    width: calc(100% + 10px);
    left: -5px;
    padding: 4px 0 5px;
  }

  .rs-divider-horizontal {
    margin: 10px;
  }
}

div.event-screenshot-btn {
  z-index: 999;
  position: absolute !important;
  right: 4px;
  top: 2px;

  // 
  .rs-btn-icon {
    opacity: 0.75;
  }
}

.events-progressline {
  position: absolute;
  left: 0;
  top: 0;
}

.eventsarchive-findface_container {
  white-space: nowrap;
  background: @panel-bg-color;
  border-radius: 30px;
  width: 180px;
  transition: 0.2s;

  display: grid !important;
  grid-template-columns: min-content auto min-content;

  // &:hover {
  //   width: 160px;
  //   & > *:last-child {
  //     display: block;
  //   }
  // }

  .rs-slider {
    margin: 14px 12px 14px 12px;

    .eventsarchive-slider_handler {
      background: @base-color;
      margin-left: -12px;
      top: -4px;
      position: absolute;
      width: 22px;
      height: 22px;
      line-height: 22px;
      vertical-align: middle;
      font-size: @font-size-extra-small;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}

.events-movebtn {
  position: absolute;
  top: 40%;
  height: 100px;
  vertical-align: middle;
  line-height: 100px;
  width: 40px;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;
  z-index: 100;

  &:hover {
    opacity: 1;
  }

  &.events-prevbtn {
    left: 20px;
  }

  &.events-nextbtn {
    right: 20px;
  }
}

.events-toarchivebtn {
  opacity: 0.4;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }
}

.eventsarchive-table {
  .rs-table-cell-header-sort-wrapper {
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
  }
}

.eventsarchive-backpanel {
  margin: 3px;
  padding: 10px 20px;

  background: rgba(0, 0, 0, .2);
  border-radius: @default-border-radius;
}

.dropdown-input {
  padding: 0 6px;
}

.eventsarchive-floormaps {
  #floormaps_canvas {
    margin-top: 0;
    resize: vertical;
  }
}
