@import '~rsuite/styles/index.less';
@import './theme.less';

html {
  background: @body-bg;
}

body {
  font-family: 'BebasNeuePro', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code,
pre {
  font-family: @font-monospace;
}

// ** panel
.rs-panel {
  background-color: @panel-bg-color;
  box-shadow: @default-shadow;
  transition: 0.3s;
  overflow: unset;

  &:hover {
    background: lighten(@panel-bg-color, 1%);
    box-shadow: @hover-box-shadow;
  }

  .rs-panel-heading {
    cursor: default;
  }
}

.rs-tag {
  line-height: 160%;
}

.rs-row {
  margin-bottom: 15px;
}
