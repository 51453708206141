@import 'theme.less';

.textfield {
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border-radius: @default-border-radius;
  cursor: default;
  min-height: 30px;

  display: grid;
  grid-template-columns: auto max-content;

  li {
    margin-left: 15px;
  }

  .textfield_text {
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .textfield_tail {
    position: relative;
    right: 0;
    top: 0;
    height: 100%;
    text-transform: uppercase;
    border-top-right-radius: @default-border-radius;
    border-bottom-right-radius: @default-border-radius;


    &[data-active='true'] {
      background: @base-color;
    }

    .rs-btn {
      display: inline-block;
      height: 30px;
      padding: 0px 18px;
      vertical-align: top;

      & > .rs-icon {
        height: 20px;
        line-height: 14px;
      }
    }
  }

}
