@import 'theme.less';

.floormaps-container {
  position: relative;
  min-height: 100px;

  text-align: center;

  & > .toolbar {
    margin-bottom: 15px;
  }

  .konvajs-content {
    margin-top: 20px;
    position: relative;
    display: inline-block;
  }


  &[data-interaction='true'] {
    .camera-canvas {
      pointer-events: unset;
    }
  }
}



.floormap-panel {
  .rs-panel-body {
    text-align: center;
  }
}

.floormaps-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: calc(100vh - 372px);
  min-height: 100px;
}

#floormaps_canvas {
  margin-top: 20px;
  position: relative;
  display: inline-block;
  border-radius: @default-border-radius;
  overflow: hidden;

  & > div {
    display: block !important;
  }
}

.floormaps-object {
  width: 46px;
  height: 46px;

  background: rgba(255, 255, 255, 0.5);

  border: 3px solid transparent;
  // transition: 0.1s;

  border-radius: 100%;
  vertical-align: middle;

  &.selected {
    border: 3px solid @base-color;

    .rs-icon {
      cursor: move;
    }
  }

  .floormaps-object_in {
    position: relative;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
  }

  .rs-icon {
    vertical-align: middle;
    font-size: 20px;
    color: @base-color;
    cursor: pointer;

    text-shadow: 0 0 5px rgba(0, 0, 0, .5);

    &.rs-icon-lightbulb-o {
      font-size: 24px;
    }

    &[data-disabled='true'] {
      color: rgba(0, 0, 0, 0.5) !important;
    }

    &[data-status='active'] {
      color: @base-color !important;
    }

    &[data-status='errors'] {
      color: @error-color !important;
    }
  }
}

.floormaps-object_active {
  &.rs-icon {
    color: @success-color;
  }
}

.floormaps-area {
  position: absolute;
  pointer-events: none;
  left: 100%;
  // overflow: hidden;
}

.floormaps-cameraview {
  position: absolute;
  background: #68b56177;
  top: -1%;
  left: -1%;
  height: 102%;
  width: 102%;
  clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 60%);
}


.floormaps-devicezone {
  position: absolute;
  width: 50px;
  height: 6px;
  background: @B200;
  top: 16px;
  left: 24px;
  z-index: -1;
  transition: 0.2s;
}

.floormaps-object_event {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 100%;
  background: @base-bg-color;
  width: 19px;
  height: 19px;
  line-height: 18px;
  font-size: @font-size-small;

  &.alert {
    background: @error-color;
  }
}

.floormaps-cameraview-items {
  position: absolute;
  // transform: rotate(90deg);
  // background: red;
  opacity: 0.9;
  // left: 125%;
  width: 100%;
  height: 100%;
  // z-index: 99;
}

.floormaps-item {
  position: absolute;
  min-width: 10px;
  min-height: 10px;

  border: 3px solid rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, .5);

  transition: 0.2s;
  color: rgba(0, 0, 0, .7);
  white-space: nowrap;
  word-break: keep-all;
  font-size: 9px;
  font-weight: bold;



  &[data-state='fail'] {
    border-color: @error-color;
  }

  &[data-state='pass'] {
    border-color: @success-color;
  }
}


.floormaps-profile {
  border-radius: 100%;
  overflow: hidden;


  &[data-state='fail'] {
    border-color: @error-color;
  }

  &[data-state='pass'] {
    border-color: @success-color;
  }
}

.floormaps-item_img {
  max-width: 100%;
  max-height: 100%;
}


.react-draggable {
  position: absolute;

  .floormaps-rotatehandle {
    width: 3px;
    height: 30px;
    background: @B200;
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: -1;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background: @B000;
      border-radius: 100%;
      position: absolute;
      top: -5px;
      left: -5px;

      cursor: ew-resize;
    }
  }
}

.floormaps-object-moveable {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 46px;
  min-height: 46px;

  margin-left: -23px;
  margin-top: -23px;
  z-index: 99;

  * {
    pointer-events: none;
  }
}

.floormaps-map {
  .floormaps-object_title {
    margin-top: 30px;
    margin-left: -30px;
  }
}


.floormaps-object_title {
  position: absolute;
  margin-top: 17px;
  margin-left: -50px;

  cursor: default;
  user-select: none;
  pointer-events: none;

  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0 0 2px #fff, 0 0 1px #fff;
  color: rgba(0, 0, 0, .8);
  font-weight: bold;
  font-size: 85%;
  padding: 0 2px;
  text-align: center;
}

.floormaps-object_count {
  display: none;
  position: absolute;
  margin-top: 2px;
  margin-left: 10px;

  border-radius: 100%;
  background: @base-bg-color;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: @font-size-small;
  text-align: center;

  &[data-alert='true'] {
    background: @error-color;
  }

  &[data-show='true'] {
    display: block;
  }
}

.floormaps-widgets {
  margin-top: 15px;
  padding-bottom: 15px;

  &[data-devices='true'] {
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .rs-grid-container-fluid {
    width: 100%;
  }
}

.floormaps-cameras {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 1%;


}

@media (max-width: 1800px) {
  .floormaps-cameras {
    grid-template-columns: 49.5% 49.5%;
  }
}

@media (max-width: 1000px) {
  .floormaps-cameras {
    grid-template-columns: 100%;
  }
}

.floormap-wgt_device {
  position: relative;
  padding: 0 5px;
}

.floormap-wgt {
  position: relative;

  .floormap-wgt-close-btn {
    position: absolute !important;
    right: -12px;
    top: -10px;
    opacity: 0;
    transition: 0.3s;
    z-index: 999;
  }

  &:hover {
    .floormap-wgt-close-btn {
      opacity: 1;
    }
  }
}

.floormaps-picker {
  position: absolute;
  opacity: 0;

  & + & {
    margin-left: 36px;
  }
}

.floormaps-timeline {
  display: grid;
  grid-template-columns: max-content auto;
}

.floormaps-timeline-event {
  position: absolute;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.5);
  width: 14px;
  height: 14px;
  margin-top: 4px;
  transition: .2s;

  &:hover {
    background: rgba(255, 255, 255, 255, 1);
  }
}



.floormaps-uploader-area {
  margin-top: 20px;

  .rs-uploader-trigger-btn {
    line-height: 300px;
  }
}

.floormaps-map {
  overflow: hidden;
  border-radius: @default-border-radius;

  & > div {
    display: block !important;
  }
}

.floormaps-map-object {
  position: relative;


  margin-left: -20px;
  margin-top: -20px;
}
