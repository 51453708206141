@import 'theme.less';

.status-content {
  padding: 10px 15px;
}

.status-container {
  padding: 10px 0;

  // display: grid;
  column-gap: 10px;
  column-count: 3;


  .status-panel {
    display: inline-block;
    width: 100%;
  }


  @media (orientation: portrait) or (max-width: 1200px) {
    column-count: 2;
  }

  @media (min-width: 1000px) and (max-width: 1400px) {
    column-count: 3;
  }

  @media (min-width: 1400px) and (max-width: 1800px) {
    column-count: 5;
  }

  @media (min-width: 1800px) {
    column-count: 6;
  }

}

.status-panel {
  height: 60px;
  // max-width: 200px;
  // min-width: 150px;
  width: 100%;
  margin-bottom: 10px;




  &[data-state='disabled'] {
    background: @B400-dark;
  }

  &[data-state='active'] {
    background: @success-color;
  }

  &[data-state='errors'] {
    background: @error-color;
  }

  &[data-state='init'] {
    background: @warn-color;
  }

  .exclamationicon {
    margin: 5px;
    vertical-align: middle;
  }

  .rs-panel-header {
    padding: 0 20px;
    overflow: hidden;
    height: 60px;
  }

  .rs-panel-title {
    font-size: 95%;
    vertical-align: middle;
    display: table-cell;
    height: 60px;
  }
}
