@import 'theme.less';

.license-table {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
  padding: 10px;

  font-size: 115%;
}

