// Enable dark mode
@enable-dark-mode: true;


@base-color: #3c74e1dd;
@primary-color: @base-color;

//** Pallet
@H050: shade(@base-color, 50%);
@H100: shade(@base-color, 40%);
@H200: shade(@base-color, 30%);
@H300: shade(@base-color, 20%);
@H400: shade(@base-color, 10%);
@H500: @base-color;
@H600: lighten(@base-color, 10%);
@H700: lighten(@base-color, 20%);
@H800: lighten(@base-color, 30%);
@H900: lighten(@base-color, 40%);

@H050-dark: shade(@base-color, 50%);
@H100-dark: shade(@base-color, 40%);
@H200-dark: shade(@base-color, 30%);
@H300-dark: shade(@base-color, 20%);
@H400-dark: shade(@base-color, 10%);
@H500-dark: @base-color;
@H600-dark: shade(@base-color, 5%);
@H700-dark: shade(@base-color, 10%);
@H800-dark: shade(@base-color, 15%);
@H900-dark: shade(@base-color, 20%);

//** Gray levels.
@B000: #fff;
@B050: #e9ebf0;
@B100: #cbced4ee;
@B200: #e2e4e5dd;
@B300: #c7c9cbcc;
@B400: #6a6f76bb;
@B500: #5c6066aa;
@B600: #414147aa;
@B700: #26262d66;
@B800: #1a1d2488;
@B900: #0f131af0;

@B000-dark: #fff;
@B050-dark: #e9ebf0;
@B100-dark: #cbced4ee;
@B200-dark: #e2e4e5dd;
@B300-dark: #87898bcc; // btn-active
@B400-dark: #6a6f76cc;
@B500-dark: #555559ee; // hover-btn-bg
@B600-dark: #414147aa; // hover  // btn-bg
@B700-dark: #26262dee; // bg-overlay
@B800-dark: rgba(0, 0, 0, 0.2); // input-bg
@B900-dark: #0f131af0;


//**



@base-bg-color: rgba(0, 0, 0, 0.3);
@panel-bg-color: rgba(30, 31, 32, 0.6);
@input-bg: rgba(0, 0, 0, 0.2);

@hover-hightlight: rgba(255, 255, 255, 0.1);

//**
@border-base-color: rgba(0, 0, 0, 0);
@border-primary-color: @H500;

@table-border-color: rgba(0, 0, 0, 0.07);
@input-border-color: shade(@B700-dark, 20%);

//**
@btn-default-bg: @B600;

//**
@body-bg: #212121;
@text-color: #eeeeee;
@text-primary-color: #ffffff;
@pagetitle-color: #ffffff;

//**
@link-color: @base-color;
@link-hover-color: @H400;
@link-active-color: @H300;
@link-hover-decoration: none;
@link-active-decoration: none;

//**
@dropdown-bg: fadein(@B700, 80%);

//**
@blurfilter: blur(10px) grayscale(50%);


//**
@default-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
@default-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
@hover-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);

@warn-color: #e29743;
@success-color: #58b15b;
@error-color: #f04f43;


// Fonts
@fonts: '~assets/fonts';
@icon-font-path: '~assets/fonts';

@font-face {
  font-family: 'BebasNeuePro';
  src: url('@{fonts}/BebasNeuePro/BebasNeuePro-ExpandedRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeuePro';
  src: url('@{fonts}/BebasNeuePro/BebasNeuePro-ExpandedExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Monaco';
  src: url('@{fonts}/Monaco/Monaco.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-regular: 'BebasNeuePro', sans-serif;
@font-monospace: 'Monaco', monospace;

@font-family-base: @font-regular;

@font-size-large: 16px;
@font-size-base: 14px;
@font-size-small: 13px;
@font-size-extra-small: 11px;

// **

@sidemenu-bg-color: @base-bg-color;
@sidemenu-fg-color: @text-color;
@sidemenu-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

//** List
@list-item-drag-bg-color: unset;
@list-border-color: unset;

//** Table
@table-body-background: unset;
@table-body-hover-background: rgba(255, 255, 255, 0.02);
@table-body-content-padding-vertical: 12px;
@table-content-padding-horizontal: 10px;

@default-border-radius: 6px;

//** Input
@input-bg: @base-bg-color;

@picker-selected-value-color: @B000;
@radio-picker-active-color: @H700;

@form-control-width: 100%;