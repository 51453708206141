.auth {
  position: fixed;
  width: 300px;
  top: 15vh;
  left: calc(50% - 150px);

  .rs-panel {
  }

  .rs-panel-heading {
    text-align: center;
  }

  & .subpanel {
    text-align: center;
    border-radius: 0 0 6px 6px !important;
  }
}

.auth-btn_submit {
  width: 50%;
}

.subtitle {
  text-align: center;
  font-size: 11px;
  opacity: 0.7;
  margin-top: 8px;
  cursor: default;
}

// .auth-camera-panel {
//   position: fixed;
//   top: 16vh;
//   width: 300px;
//   left: calc(50% - 150px);
//   z-index: 2;

// }

.auth-camera_container {
  margin-top: 10px;
  position: relative;

  .camera-canvas-stat {
    display: none;
  }

  .camera-canvas-status {
    top: 10px;
  }
}

.auth-camera {
  width: 100%;
  min-height: 142px;

  border-radius: 5px;
}

