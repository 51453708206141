@import 'theme.less';

.ace_editor {
  position: relative;
  min-height: 40px;
  background-color: @panel-bg-color !important;
  border-radius: @default-border-radius;
  line-height: 1.26em;
  opacity: 0.9;

  .ace_gutter {
    background-color: @base-bg-color !important;
  }
  .ace_gutter-active-line,
  .ace_active-line {
    background: rgba(255, 255, 255, 0.1) !important;
  }
}

.ace_autocomplete {
  & .ace_scroller {
    background-color: fade(@base-bg-color, 90%);
  }
}

