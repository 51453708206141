@import 'theme.less';

.profile-photo-panel {
  margin: 7px;
  position: relative;

  & .close-btn {
    opacity: 0;
  }

  &:hover .close-btn {
    opacity: 0.9;
  }
}

.profile-photo {
  background: @input-bg;
}

.profile-form {
  max-width: 600px;
  margin: 0 auto;

  .rs-divider-horizontal {
    margin: 0;
  }
}

.profile-actions-panel {
  padding: 10px;
  margin-bottom: 15px;
}

// .rs-dropdown-menu {
//   position: absolute;
//   z-index: 999 !important;
// }

.profile-dnduploader {
  margin-bottom: 15px;
  border-radius: @default-border-radius;
  min-height: 200px;
  padding: 7px;
  background: rgba(0, 0, 0, .2);

  &.dragon {
    background: rgba(0, 0, 0, .4);

    & .dnd-icon {
      opacity: .7;
    }
  }

  .dnd-icon {
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 40%;
    left: calc(50% - 20px);
    font-size: 40px;
    opacity: .4;
    transition: .3s;
  }
}

.profile-cropper {
  min-width: 300px;
  max-width: 600px;
  max-height: 600px;
}

.profile-cropper-toolbar {
  margin-top: 10px;
}

.profile-icon-rotateleft {
  transform: scaleX(-1);
}

.profile-cropper-savebtn {
  position: absolute;
  right: 20px;
}

.profile-photo-placeholder {
  position: absolute;
  top: 0;
  margin: 60px;
  top: 8px;
  opacity: .5;
}
