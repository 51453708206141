@import 'theme.less';

.bbox {
  position: absolute;
  border: 2px solid @B050;
  opacity: 0.75;
  transition: 0.3s;

  &[data-state='pass'] {
    background: fade(@success-color, 20%);
    border-color: @success-color;
  }
  &[data-state='fail'],
  &[data-alert='true'] {
    background: fade(@error-color, 20%);
    border-color: @error-color;
  }
  &[data-state='hide'] {
    opacity: 0;
  }

  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    .bbox-menu {
      opacity: 1;
    }
  }

  &[data-border='thick'] {
    border-width: 4px;
    opacity: 0.85;

    .bbox-texts {
      top: -3px;
      right: calc(100% + 6px);
    }
    .bbox-text {
      font-size: 16px;
      padding: 4px;
    }
  }
}

.bbox-texts {
  position: absolute;
  right: calc(100% + 4px);
  top: -1px;
  opacity: 0.9;
}

.bbox-field {
  display: block;
  width: 100%;
  overflow: hidden;
}

.bbox-text {
  display: inline-block;
  background: @B000;
  color: @B900;
  padding: 1px 2px 1px 2px;
  line-height: 12px;
  font-size: 10.5px;
  margin-bottom: 1px;
  float: right;
  white-space: nowrap;
  font-weight: bolder;
  max-width: 100px;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;

  &[data-state='pass'] {
    background: @success-color;
    color: @B000;
  }
  &[data-state='fail'],
  &[data-alert='true'] {
    background: @error-color;
    color: @B000;
  }
}

.bbox-emotions {
  position: absolute;
  top: -2px;
  left: calc(100% + 4px);

  & > .bbox-field {
    position: relative;
    background: @B000;
    margin-bottom: 1px;
  }
}

.bbox-emotion {
  color: @B900;
  position: relative;
  text-transform: uppercase;
  text-overflow: ellipsis;
  font-size: 10.5px;
  padding: 1px 2px 1px 2px;
  z-index: 2;
}

.bbox-fill {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: @success-color;
  z-index: 1;
}

.bbox-color {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  // margin: 0.5px 2px;
  top: 1px;
  right: 1px;
  display: inline-block;
}

.bbox-menu {
  opacity: 0;
  position: absolute;
  right: -12px;
  top: -15px;
}

.item-track {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;

  min-width: 2px;
  min-height: 2px;
}

