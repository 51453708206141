@import './theme.less';

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1040;
  background: @panel-bg-color;
  box-shadow: @default-shadow;
  backdrop-filter: @blurfilter;
  transition: 0.2s;
  cursor: default;
  min-width: 240px;
  max-width: 240px;

  display: grid;
  grid-template-rows: min-content auto min-content;

  &[data-expand='true'] {
    & ~ .content {
      margin-left: 240px;
    }
  }

  &[data-expand='false'] {
    min-width: 56px;

    .menu-title {
      display: none;
    }

    .menu-block {
      width: 56px;
      padding: 10px 8px;
      grid-template-columns: min-content;

      .menu-logo {
        max-width: 50px;
      }
    }

    .menu-content {}

    .menu-footer-username {
      opacity: 0;
      font-size: 1px;
      height: 5px;
    }

    .menu-health {
      padding: 0 2px;
      grid-template-columns: min-content;
      width: 52px !important;

      .health-title {
        padding: 0;
        font-size: 12px;
      }

      .menu-health-bar {
        width: 56px;
      }

      .menu-health-label {
        padding: 0 4px;
        font-size: 12px;
      }

      .health-prefix {
        display: none;
      }

      .health-group {
        margin-top: 0;
      }

      .health-value,
      .health-subtitle {
        text-align: right;
        font-size: 10px;
      }

      .rs-progress-line {
        padding: 0 4px;
      }
    }

    & ~ .content {
      margin-left: 60px;
    }
  }

  .menu-block {
    padding: 12px 10px;

    .menu-logo {
      padding-right: 12px;
      padding-left: 5px;
      max-width: 200px;
    }

    .menu-title {
      font-size: 16pt;
      line-height: 40px;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .togglebar-btn {
    right: -12px;
  }

  &[data-expand="false"] {
    .rs-dropdown-menu {
      position: fixed;

      top: unset !important;
      margin-top: -53px !important;
    }

    .ps__rail-y,
    .ps__rail-x {
      display: none !important;
    }
  }

  .rs-dropdown-open {
    .rs-dropdown-menu {
      display: block !important;
    }
  }

  .rs-dropdown-item:hover,
  .rs-sidenav-item:hover,
  .rs-dropdown-toggle:hover {
    background: @input-bg !important;
  }

  // .rs-sidenav-item {
  //   .rs-sidenav-item-icon {
  //     font-size: 18px !important;
  //     margin-right: 6px;
  //     vertical-align: text-top;
  //   }
  // }

  .rs-sidenav-item-icon {
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 18px;
    height: 18px;
    max-width: 18px;
    overflow: hidden;
  }

  img.rs-sidenav-item-icon {
    font-size: 0;
    -webkit-filter: brightness(0.1) invert(1);
    filter: brightness(0.1) invert(1);
    opacity: .85;
  }


}

.menu-content {
  height: 100%;
  display: grid;
  grid-template-rows: auto min-content;
  // overflow: auto;
}

.menu-footer {
  position: relative;
  width: 100%;
  margin-top: 0 !important;

  .menu-footer-username {
    padding-left: 10px;
    text-overflow: ellipsis;
    line-height: 40px;
    font-size: @font-size-base;
    transition: 0.2s;
  }
}

.menu-user {
  display: grid;
  grid-template-columns: min-content auto min-content;
}

.menu-health {
  position: relative;
  padding: 5px;
  bottom: 0;
  width: 100%;

  .rs-progress-line {
    padding: 1px 12px;
  }

  .menu-health-bar {
    position: relative;
  }

  .menu-health-label {
    position: relative;
    padding: 0 15px;
    top: 3px;
    opacity: 0.8;
    font-size: @font-size-small;
    overflow: hidden;
    white-space: nowrap;

    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 5px;
  }

  .health-prefix {
    display: inline-block;
    margin-top: -1px;
    vertical-align: middle;
    font-weight: bold;
    // max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .health-value {
    white-space: nowrap;

    & > .rs-icon {
      margin-left: 5px;
    }
  }

  .health-group {
    margin-top: 2px;
    position: relative;
  }

  .health-title {
    position: relative;
    top: 8px;
    text-align: center;
    // margin-bottom: -10px;
    font-weight: bold;
    padding: 0 15px;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .health-subtitle {
    position: relative;
    padding: 0 15px;
    // margin-top: -10px;
    opacity: 0.8;
    font-size: @font-size-small;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
  }
}

#version {
  position: fixed;
  bottom: 2px;
  left: 10px;
  font-size: 10px;
  opacity: 0.7;
  z-index: 999;
}

.menuitem-imgicon {
  width: 20px;
}
