@import 'theme.less';

.exc-columns {
  margin-top: 10px;
  column-gap: 20px;
}

.exc-panel {
  position: relative;
  display: inline-block;
  width: 100%;

  cursor: pointer;
  margin-bottom: 20px;
  min-height: 30px;

  &:hover {
    .exc-header {
      opacity: 0.8;
    }
  }

  &[data-selected='true'] {
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: solid 4px @base-color;
      border-radius: @default-border-radius;
    }
  }
}

.exc-image {
  border-radius: @default-border-radius;
  width: 100%;
}

.exc-header {
  position: absolute;
  opacity: 0.3;
  padding: 10px;
  background: @input-bg;
  width: 100%;
  border-radius: @default-border-radius @default-border-radius 0 0;

  .rs-tag {
    margin-left: 5px;
  }

  h4 {
    line-height: 22px;
    font-size: 16px;
  }

  display: grid;
  grid-template-columns: min-content min-content min-content;
}

