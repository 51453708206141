@import './theme.less';

h5 {
  margin-bottom: 10px;
}

.link {
  color: @base-color;
  cursor: pointer;
  font-size: 110%;

  // text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.3);
  &.linkfocus {
    font-weight: bold;
  }
}

.disabled {
  pointer-events: none;
  opacity: .7;
}


.tagbtn {
  cursor: pointer;
}

.rs-panel-title {
  & h5 {
    display: inline-block;
    margin-right: 15px;
  }

  text-transform: uppercase;
  font-size: @font-size-large;
  font-weight: bold;
}

.panel-title-withtoolbar {
  display: grid;
  grid-template-columns: auto min-content;

  .content-calendar {
    display: inline-block;
  }
}



.rs-sidebar {
  box-shadow: @sidemenu-shadow;
}

.rs-avatar {
  & > .rs-avatar-image {
    height: auto !important;
  }
}

.togglebar-btn {
  position: absolute !important;
  top: 20px;
  transition: 0.2s;
  z-index: 9999;

  & > .rs-icon {
    padding: 1px !important;
  }
}

.content {
  position: relative;
  transition: 0.15s;
  padding: 15px 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
}

.content-header {
  min-height: 30px;

  .rs-grid-container-fluid {
    padding: 0;
  }

  & .rs-picker-toggle {
    border-radius: 50px !important;
    border: none;
  }

  & .rs-col {
    margin-bottom: 2px !important;
  }

  .rs-picker {
    border-radius: 50px !important;
    border: none !important;
  }

  .rs-btn:hover {
    color: @text-color !important;
  }
}

.content-search {
  width: 200px !important;
  margin: 0 auto;
  border-radius: 50px !important;
  border: none !important;

  .rs-input-group-addon {
    padding: 10px 10px !important;
  }

  .rs-input {
    border: none;
    background: @panel-bg-color;
    color: @B100;
  }

  .rs-icon {
    position: relative;
    opacity: 0.5;
    line-height: 10px;
    top: -2px;
  }
}

.search-dropdown {
  &.selected {
    button {
      background-color: @base-color;
    }
  }
}

ul.search-dropdown {
  max-height: 400px;
  overflow: auto;
}

.content-header_toolbar {
  text-align: center;

  .rs-btn {
    border-radius: 100%;

    &.selected {
      background: @base-color;
    }
  }

  .rs-uploader {
    .rs-uploader-trigger {
      .rs-btn {
        border-top-left-radius: 100% !important;
        border-bottom-left-radius: 100% !important;
      }
    }
  }
}

.content-calendar {
  width: 200px !important;
  border-radius: 50px !important;
  background: @panel-bg-color !important;

  .rs-picker-toggle.rs-btn {
    background: none !important;
    border: none;

    padding: 7px;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: @B200;
  }

  .rs-picker-toggle-caret {
    display: none;
  }


  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused {
    box-shadow: none;
  }

  .rs-picker-toggle-textbox {
    display: none;
  }

  .rs-icon {
    position: relative;
    opacity: 0.5;
    line-height: 10px;
    top: -2px;
  }
}

.content-header {
  .content-header_left {
    .header-picker {
      width: 200px !important;
      border-radius: 50px !important;
      background: @panel-bg-color !important;
      border: 1px solid @input-border-color !important;
    }
  }
}

.content-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  cursor: default;
  letter-spacing: 1px;

  text-transform: uppercase;
  font-size: @font-size-large;
  font-weight: bold;
  color: @pagetitle-color;
  line-height: 18px;
}

.rs-panel-heading {
  text-transform: uppercase;
  font-size: @font-size-large;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: 0.8;
  padding-bottom: 0;
  word-break: break-all;

  .rs-panel-title {
    line-height: 30px;
  }

  .rs-btn-group {
    position: absolute;
    right: 22px;
    margin-top: -4px;
  }

  .rs-tag {
    margin-left: 5px;
  }

  // .rs-icon {
  //   margin-left: 5px;
  // }
}

.content-error {
  margin: 5px 0;
}

.content-panel {
  position: relative;
  margin: 10px 0 15px 0;

  & .content-grid {
    padding: 0;
  }
}

.content-grid {
  .rs-col {
    margin-bottom: 15px;
  }
}

form {
  .rs-row {
    margin-bottom: 4px;
  }

  .rs-col {
    margin-bottom: 4px;
  }
}

.rs-row {
  margin-bottom: 0;
}


.rs-header {
  .rs-col {
    margin-bottom: 0;
  }
}

.rs-modal-header {
  .rs-tag {
    vertical-align: middle;
    cursor: default;
    margin: -2px 5px 0;
  }
}

.rs-dropdown-item-content {
  .rs-col {
    margin-bottom: 0;
  }
}

.rs-dropdown {
  .rs-dropdown-menu {
    z-index: 999;
  }
}

.rs-dropdown-menu {
  .rs-divider-horizontal {
    margin: 8px 0;
  }

  .rs-dropdown-item-menu-icon {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    top: 2px;
    width: 30px;
    font-size: 90%;
  }
}

.title {
  font-size: 18pt;
}

.table-withoutborders {
  .rs-table-row {
    border-bottom: unset;
  }
}

.rs-table-cell-last {
  .rs-table-cell-content {
    padding-right: 10px;
  }
}

.rs-tag {
  // cursor: default;

  &[data-state='pass'] {
    background-color: @success-color;
  }

  &[data-state='fail'],
  &[data-alert='true'] {
    background-color: @error-color;
  }
}

.rs-btn {
  &[data-checked='false'] {
    // background-color: @B400;
  }

  &[data-checked='true'] {
    background-color: @base-color;
  }

  &.rs-btn-icon {
    line-height: 10px !important;
  }

  & > .rs-icon {
    margin-right: 3px;
  }
}

.rs-picker-tag-wrapper {
  .rs-tag {
    line-height: 20px !important;
  }
}

.circle-btn {
  border-radius: 100px;

  & > .rs-icon {
    height: 14px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}

.subpanel {
  position: relative;
  border-radius: 0 0 6px 6px;
  margin: 0 20px;
  opacity: 0.7;
}

.mjpeg-background {
  position: relative;
  min-width: 32px;
  min-height: 32px;
  background-size: cover;
  background-position: center;
  border-radius: @default-border-radius;
}

.actions-cell {
  .rs-table-cell-content {
    padding: 5px 5px;
  }
}

// .rs-picker-default .rs-btn,
// .rs-picker-input .rs-btn,
// .rs-picker-default .rs-picker-toggle,
// .rs-picker-input .rs-picker-toggle {
//  background: none !important;
// }

input::placeholder {
  opacity: 0.35;
}

.rs-input-number {
  & input {
    text-align: right;
  }
}

.rs-input-group {
  display: flex !important;
}

.rs-input-group-btn {
  text-transform: uppercase;
}

.rs-picker-block {
  width: 100%;
}

.rs-picker-input {
  & > .rs-picker-toggle {
    background: none !important;
  }
}

.form-type-selector {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.form-section-checkbox {
  label {
    .rs-checkbox-wrapper {
      margin-top: 3px;
    }
  }
}

.rs-input,
.rs-picker-input,
.rs-input-group,
.rs-radio-group-picker,
.rs-picker-toggle {
  background: @input-bg;
  border-color: @input-border-color !important;
}



.rs-input-group {

  .rs-input,
  .rs-picker,
  .rs-picker-input,
  .rs-input-number {
    background: none !important;
  }

  .input-group-checkbox {
    padding: 0 5px 0 8px !important;
  }

  .rs-form-control-wrapper {
    display: table-cell !important;
  }

  .rs-picker-input {
    border: none;
    border-radius: 0;
    display: table-cell;
  }

  .rs-tag-group {
    margin: 2px 0;

    .rs-tag {
      margin-top: 0;
      line-height: 24px;
      font-size: @font-size-base;
    }
  }

  .rs-uploader {
    .rs-uploader-trigger-btn {
      padding: 10px 12px;
    }

    .rs-uploader-trigger-customize {
      height: 100%;

      & > .rs-btn {
        height: 100%;
      }
    }
  }



  .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    border: none;
    background: #414147aa;
  }

  .rs-form-control-label {
    position: absolute;
    top: -17px;
    width: 100%;
    text-align: center;
    opacity: .8;

    font-size: 90%;

  }
}

.rs-picker {
  .rs-tag-md.rs-tag-closable {
    padding-left: 5px;
    padding-right: 16px;

    .rs-tag-icon-close {
      padding: 5px 3px;
      right: 1px;
    }
  }
}

.rs-form-control-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  margin-bottom: -2px;
}

.input-group-block {
  position: relative;
  width: 100%;
}

.rs-uploader-trigger-btn {
  border: none !important;
  transition: .2s;
}

.rs-control-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;


  & > .rs-help-block-tooltip {
    margin-top: 0;
  }

  & > a {
    margin: 0 5px;
  }
}

.rs-picker-tag {
  & .rs-tag {
    padding-top: 1px;
    max-width: 120px;
    line-height: 22px;
  }
}

.rs-picker-select-menu-item {
  & .rs-tag {
    margin: 0 5px;
  }
}

.rs-tag {
  line-height: 18px;
  font-size: @font-size-small;

  a {
    color: inherit;
  }
}

.footer {
  margin: 15px 0 10px;

  .rs-btn-toolbar {

    & > .rs-btn:not(.rs-btn-icon),
    & > .rs-uploader-trigger-btn {
      margin: 5px 5px;
      min-width: 120px;
    }
  }

  .rs-btn-group {
    .rs-btn:not(.rs-btn-icon) {
      min-width: 120px;
    }
  }


  & > .rs-btn:not(.rs-btn-icon),
  & > .rs-uploader-trigger-btn {
    margin: 5px 5px;
    min-width: 120px;
  }
}

.footer-buttons {
  margin-top: 15px;
  text-align: right;
  display: grid;
  grid-template-columns: auto auto;

  .rs-btn-toolbar {

    & > .rs-btn:not(.rs-btn-icon),
    & > .rs-uploader-trigger-btn {
      margin: 5px 5px;
      min-width: 120px;
    }
  }

  .rs-btn-group {
    .rs-btn:not(.rs-btn-icon) {
      min-width: 120px;
    }
  }
}



.header-buttons {
  text-align: right;
}

.rs-btn-group-md {
  & > .rs-btn:not(.rs-btn-icon) {
    padding: 8.5px 12px;
  }
}

.rs-btn-toolbar {
  white-space: nowrap;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  .uploader-toolbar-lastbtn {
    display: inline-block;

    // WARD: used only if uploader is a last button on the toolbar

    .rs-uploader-trigger-btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .rs-divider {
    opacity: 0;
    margin-right: 5px;
  }
}

.rs-btn-xs {
  &.rs-btn-icon {
    & > .rs-icon {
      line-height: 24px;
    }
  }
}

.analytics_tag {
  background: @hover-hightlight;
  font-size: @font-size-small;
  line-height: 20px;
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }
}

.form-panel {
  width: 65%;
  margin: 0 auto 40px;
  position: relative;
}

.rs-form {
  .text-info {
    line-height: 40px;
    font-size: @font-size-large;
    opacity: 0.8;

    & > .rs-icon {
      margin-right: 5px;
    }
  }

  .text-info-2row {
    line-height: 18px;
    font-size: @font-size-base;
    opacity: 0.7;
    padding-left: 42px;

    & > .rs-icon {
      left: 22px;
      line-height: 40px;
      font-size: 22px;
      margin-right: 5px;
      position: absolute;
    }
  }

  .rs-control-label {
    min-height: 20px;
  }
}

.settingsinput {
  margin-bottom: 10px;

  .settingsinput-item {
    line-height: 36px;
  }
}

.settingsinput-rowitem + .settingsinput-rowitem {
  padding-left: 10px;
}

.close-btn {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 10;
  transition: 0.2s;
}

.wgt-close-btn {
  position: absolute !important;
  top: 5px;
  right: 10px;
  z-index: 10;
  transition: 0.2s;
  font-size: 80%;
}

.content-footer {
  margin: 30px 15px 10px 15px;

  .rs-btn {
    line-height: 20px;
    width: 36px;

    & > .rs-icon {
      line-height: 23px;
    }
  }
}

.table-images-cell {
  .rs-table-cell-content {
    padding: 4px 3px;
  }
}



.settings-table {
  display: grid;
  grid-template-columns: 250px auto;
  column-gap: 20px;
  line-height: 36px;
}

.rs-table-cell-header {
  text-transform: uppercase;
  color: @B200;
  opacity: 0.6;
  font-weight: bold;
  cursor: default;
  text-align: center;

  .rs-checkbox-checker {
    padding: 0px;
    width: 36px;

    .rs-checkbox-wrapper {
      top: 0;
    }
  }

  .rs-table-cell-header-sort-wrapper {
    position: absolute;
    top: 12px;
    right: 0;
  }

}

.rs-checkbox {
  max-width: 100%;
}

.rs-checkbox-checker {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .rs-tag {
    margin-left: 5px;
  }

  // label {
  //   text-transform: capitalize;
  // }
}

.control-table-header-cell {
  opacity: 1;

  .rs-table-cell-last {
    .rs-table-cell-content {
      padding-right: 5px;
    }
  }
}

.profile-photo-circle {
  border-radius: 100%;
}

.rs-btn-toggle {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

  &::after {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
}

.rs-toggle-checked {
  &[data-errors='true'] {
    .rs-toggle-presentation {
      background: @error-color !important;
    }
  }

  &[data-errors='old'] {
    .rs-toggle-presentation {
      background: @warn-color !important;
    }
  }


}

.rs-btn-toggle-sm {
  height: 18px;
  min-width: 32px;
  border-radius: 8px;

  & > .rs-btn-toggle-inner {
    height: 16px;
    font-size: 16px;
  }

  &::after {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    top: 1px;
    left: 1px;
  }

  &.rs-btn-toggle-checked {
    &::after {
      left: calc(100% - 6px);
    }
  }
}


.status-icon {
  font-size: 13px;
  margin: 0 4px;
  vertical-align: middle;
}

.help-icon {
  margin: 0 4px !important;
  vertical-align: sub;
  font-size: 13px;
  opacity: .7;
}

.rs-icon[data-errors='true'],
svg[data-errors='true'] {
  color: @error-color !important;
}

.rs-icon[data-enabled='false'],
svg[data-enabled='false'] {
  color: @B500;
}

.rs-icon[data-enabled='true'],
svg[data-enabled='true'] {
  color: @success-color;
}

.rs-icon[data-status='active'],
svg[data-state='active'] {
  color: @success-color !important;
}

.rs-icon[data-status='errors'],
svg[data-state='errors'] {
  color: @error-color !important;
}

.rs-icon[data-status='disabled'],
svg[data-state='disabled'] {
  color: @B500 !important;
}

.rs-icon[data-state='pass'],
svg[data-state='pass'] {
  color: @success-color;
}

.rs-icon[data-state='fail'],
svg[data-state='fail'] {
  color: @error-color;
}

.errors-panel {
  font-family: @font-monospace;
}

.errors-text {
  font-size: @font-size-extra-small;
  white-space: pre-wrap;
  word-break: break-all;
}

.errors-time {
  font-size: @font-size-extra-small;
  margin-right: 15px;
}

.rs-divider + .rs-divider {
  display: none;
}

.center-row {
  margin: 15px 5px;

  .rs-panel {
    margin-bottom: 20px;
  }
}

.center-panel {
  width: 65%;
  margin: 0 auto 40px;

  .rs-panel-body {
    position: relative;
  }
}

.delete {
  color: @error-color !important;

  & > .rs-dropdown-item-content {
    color: @error-color !important;
  }
}

.rs-tooltip,
.rs-popover {
  white-space: pre-wrap;

  &[data-state='error'] {
    background-color: @error-color;

    & > .rs-popover-arrow::after {
      border-top-color: @error-color;
    }
  }

  &[class*="placement-top"] {
    & > .rs-popover-arrow::after {
      bottom: -5px !important;
    }
  }
}

.stat-panel {
  margin: 0 5px 10px 5px !important;

  & > .rs-panel-header {
    padding: 12px 15px 0 15px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;

    & > .rs-panel-title {
      font-size: @font-size-small;
    }
  }

  & > .rs-panel-body {
    padding: 0 15px 12px;
  }

  &[data-state='pass'] {
    background-color: @success-color;
  }

  &[data-state='fail'] {
    background-color: @error-color;
  }

  &[data-state='warn'] {
    background-color: @warn-color;
  }
}

.rs-panel {
  border-radius: @default-border-radius;
  backdrop-filter: @blurfilter;

  &[data-state='pass'] {
    background-color: @success-color;
  }

  &[data-state='fail'] {
    background-color: @error-color;
  }

  .rs-table {
    .rs-table-row-header {
      background: none;
    }

    .rs-table-cell {
      background: none;
    }
  }
}

.rs-table-cell-onerow {
  overflow: hidden;
  text-overflow: ellipsis;

  & > .rs-tag + .rs-tag {
    margin-left: 5px;
  }
}

.rs-modal {
  .rs-panel {
    box-shadow: none;
    background-color: unset;
    transition: unset;
  }
}

.rs-picker-toggle-clean {
  background: unset;
  color: unset;
}

// .rs-panel-heading {
//   padding-bottom: 0;
// }

.table-mini-btn {
  margin-top: 4px;
  width: 26px;
  height: 26px;
  padding: 4px;
}

.table-mini-icon-btn {
  opacity: 0.7;
}

.table-vertical-th {
  .rs-table-cell-content {
    padding: 5px;
    font-size: @font-size-extra-small;
  }
}

.emptyicon {
  display: block;
  // height: 32px;
  width: 23.57px;
  margin-right: 5px;
}

.selected {
  color: @B000 !important;
  font-weight: bold;
}

.table-edit {
  background: @input-bg;
  border-radius: @default-border-radius;
}

.table-content-edit {
  & .rs-table-cell-content {
    padding-top: 0;
    padding-bottom: 0;

    & > .rs-input {
      height: 100%;
      border: none;
      border-radius: 0;
      background: none;
    }

    & > .rs-btn,
    & > .rs-btn-group {
      margin-top: 5px;
    }
  }
}

.table-content-status {
  & > .rs-table-cell-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.rs-table-row {
  &:hover .onhover {
    opacity: 1;
  }
}

.onhover {
  opacity: 0;
  transition: 0.2s;
}

.popup-wide {
  min-width: 25vw;
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px !important;
}

.rs-control-label {
  .rs-tag {
    vertical-align: top;
    margin-left: 5px;
  }
}

.log {
  font-family: @font-monospace;
  font-size: @font-size-extra-small;
  opacity: 0.8;
}

.time-picker {
  .rs-btn-group {
    display: grid;
    grid-template-columns: auto 20px auto;
    text-align: center;
    line-height: 32px;
  }

  .rs-input {
    width: 40px;
  }

  .rs-input-group-addon {
    padding: 8px 1px;
  }

  .rs-btn-toolbar {
    margin-top: 10px;
  }
}

.dropdown-label {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  cursor: default;
}

.dropdown-help {
  font-size: 11px;
  opacity: 0.7;
  margin: 0;
}

.multiline_msg {
  white-space: pre-wrap;
}

.help {
  margin: 0 5px;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
}

.rs-btn-group-lg .rs-btn {
  padding: 10px 0px !important;
  min-width: 32px;
}

.rs-btn-group > .rs-btn,
.rs-btn-group-vertical > .rs-btn {
  float: none !important;
}

.pages-iframe {
  border: none;
  width: 100%;
  height: calc(100vh - 80px);
}

.rs-input {
  &[required] {
    border-color: @base-color;
  }
}

.scannermenu {
  min-width: 400px;

  .rs-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.scannermenu-item {
  width: 100%;
  display: grid;
  grid-template-columns: auto max-content;
}

.rs-message-body {
  white-space: pre-wrap;
}


.dropdown-buttons {
  min-width: 100px;
}


a {
  & > svg {
    margin-bottom: -2px;
  }
}

.rs-table {
  & + .rs-pagination-group {
    margin-top: 10px;

    .rs-pagination {
      margin: 0 auto;
    }
  }
}

.rs-form-help-text-tooltip {
  margin-top: 0 !important;
}

.rs-form-help-text {
  min-height: 0 !important;
}

.rs-btn-group {
  .rs-icon {
    height: 14px;
    line-height: 14px !important;
  }
}

.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background: none !important;
}

.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:hover .rs-table-cell {
  background: rgba(200, 200, 200, 0.02);
}

.rs-table-cell {
  border-bottom: none !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-color: @B000;
}

.input-monospace {
  font-family: @font-monospace !important;
  font-size: @font-size-small;
}


.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: @text-color;
}

.rs-picker-menu-group-title {
  font-weight: bold;
}

.selectwithicon {
  position: relative;

  .rs-picker {
    position: absolute;
    left: 0;
    width: 34px !important;
    min-width: 0 !important;
    height: 34px !important;
    padding: 0 !important;
    opacity: 0;

    .rs-picker-toggle {
      width: 34px !important;
      min-width: 0 !important;
      height: 34px !important;
      padding: 0 !important;
    }
  }
}

.stat-panel-activity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
}

.activity-calendar {
  .rs-calendar-table-cell-content {
    height: 36px !important;
  }
}

.unit {
  opacity: .7;
  margin: 0 2px;
  font-size: 90%;
}

.ps__rail-y {
  z-index: 999;
}

.modal-draggable {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  top: 30%;

  & + .moveable-control-box {
    display: none !important;
  }
}

.rs-modal-wrapper {
  z-index: 1090 !important;
}
