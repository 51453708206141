@import 'theme.less';

.themes-list {
  margin: 15px 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 140px;
  grid-gap: 20px;
}

.themes-img {
  position: relative;

  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  opacity: 0.8;
  transition: 0.2s;

  background-size: cover;
  background-position: center;
  border-radius: @default-border-radius;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

  .themes-img-closebtn {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .themes-img-applybtn {
    position: relative;
    margin: 53px auto;
  }

  & > .rs-btn {
    opacity: 0;
    transition: 0.2s;
  }

  &:hover {
    opacity: 1;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);

    .rs-btn {
      opacity: 1;
    }
  }
}

.themes-add {
  position: relative;

  width: 100%;
  height: 100%;
  border-radius: @default-border-radius;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .rs-icon {
    height: 30px;
    width: 30px;
    margin-top: 50px;
  }

  .rs-uploader-trigger {
    text-align: center;
    height: 100%;
  }

  .rs-uploader-trigger-btn {
    width: 100%;
    height: 100%;
    background: none !important;
    border: none !important;
  }

  .rs-uploader-trigger-drag-over {
    background: rgba(0, 0, 0, 0.2);
  }
}

.themes-logo {
  display: inline-block;

  .rs-uploader-trigger-btn {
    max-height: 64px;
    max-width: 100%;
    background: none !important;
    border: none !important;
  }

  .rs-uploader-trigger {
    background: rgba(0, 0, 0, 0);
    transition: 0.3s;
  }

  .rs-uploader-trigger-drag-over {
    background: rgba(0, 0, 0, 0.2);
  }

  img {
    max-height: 40px;
  }
}


.ace_editor {
  min-height: auto;
}
