@import 'theme.less';

.livestream {
  position: relative;
  margin: 7px 0 15px;
  column-gap: 10px;

  &[data-columns='2'] {
    column-count: 2
  }

  &[data-columns='1'] {
    column-count: 1
  }
}

.livestream-camera {
  position: relative;
  cursor: default;
  margin-bottom: 5px;
  display: inline-block !important;
  width: 100%;

  .camera {
    max-height: none !important;
  }
}

.watermark {
  position: absolute;
  opacity: 0.1;
  bottom: 10px;
  right: 10px;
  max-width: 30%;
  max-height: 50%;
  pointer-events: none;
}

.heatmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  pointer-events: none;
  border-radius: @default-border-radius;
}

.camera-player-container {
  position: relative;
}

.camera {
  position: relative;
  cursor: default;
  max-height: 70vh;
  min-width: 200px;

  &:hover {

    .camera-actions,
    .camera-header,
    .camera-footer {
      opacity: 1;
    }
  }


  .camera-actions,
  .camera-header,
  .camera-footer {
    opacity: 0;
  }

  .rs-panel-body {
    position: relative;
  }

}



.camera-form {
  text-align: center;
  margin-bottom: 5px;

  .camera {
    display: inline-block;
    margin: 0;

    .camera-player {
      max-height: 70vh;
      max-width: 100%;
      width: auto;
    }
  }
}

.videoarchive-camera {
  text-align: center;

  .camera {
    display: inline-block;
    margin: 0;

    .camera-player {
      max-height: 70vh;
      max-width: 100%;
      width: auto;
    }
  }

  &[data-expand='true'] {
    .camera-player {
      max-height: none !important;
    }
  }
}

.camera-player {
  position: relative;
  display: block;
  width: 100%;
  min-height: 120px;
  border-radius: @default-border-radius;
  margin: 0 auto;
}

.camera-state {
  position: absolute;
  top: 60px;
  width: 90%;
  left: 5%;
  font-size: @font-size-base;
  text-align: center;
  opacity: 0.8;
}

.camera-header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  background: @base-bg-color;
  border-radius: @default-border-radius @default-border-radius 0 0;
  transition: 0.3s;
  font-size: 95%;
  white-space: nowrap;
  opacity: 0.5;

  display: grid;
  grid-template-columns: min-content auto min-content;
  padding: 5px 10px;

  & > h6 {
    text-align: center;
    line-height: 46px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    width: 100%;
  }

  .rs-icon {
    width: 24px;
    color: @B000;
  }

  .camera-name {
    line-height: 36px;
    text-align: center;
  }

  .camera-actions {
    text-align: right;
    // opacity: 0;
    transition: 0.3s;
  }
}

.camera-footer {
  text-align: center;
  background: @base-bg-color;
  border-radius: 0 0 6px 6px;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 0 2px 0;
}

.camera-canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  &[data-interaction='true'] {
    pointer-events: unset;

    .camera-canvas {
      pointer-events: unset;
    }
  }
}

.camera-canvas-items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  &[data-selectable='true'] {
    z-index: 90;
    pointer-events: unset;
  }
}

.analyticsline {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  z-index: 210;

  &[data-editable='true'] {

    .analyticsline-arrow_right,
    .analyticsline-arrow_left {
      opacity: 0.8;
    }

    .analyticsline-arrow_right:hover,
    .analyticsline-arrow_left:hover {
      opacity: 1;
    }
  }

  .analyticsline-line {
    position: relative;
    width: 100%;
    height: 2px;
    top: -1px;
    background-color: red;
  }

  .analyticsline-arrow_left {
    position: absolute;
    left: calc(50% - 8px);
    top: 5px;
    width: 0;
    height: 0;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid white;
    opacity: 0.5;
  }

  .analyticsline-arrow_right {
    position: absolute;
    left: calc(50% - 8px);
    top: -25px;
    width: 0;
    height: 0;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid white;
    opacity: 0.5;
  }
}

.camera-canvas-status {
  position: absolute;
  top: 50px;
  left: 25%;
  width: 50%;
  padding: 1%;
  text-align: center;
  line-height: 25px;
  font-size: 22px;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  white-space: pre-wrap;

  &[data-state='pass'] {
    background: fade(@success-color, 20%);
    color: @success-color;
  }

  &[data-state='fail'],
  &[data-alert='true'] {
    background: fade(@error-color, 80%);
    animation-name: statusalert;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes statusalert {
      0% {
        background-color: fade(@error-color, 80%);
      }

      50% {
        background-color: fade(@error-color, 30%);
      }

      100% {
        background-color: fade(@error-color, 80%);
      }
    }
  }
}

.camera-canvas-stat {
  position: absolute;
  top: 50px;
  left: 5px;
  color: white;
  background: @base-bg-color;
  max-width: 24%;
  pointer-events: none;

  .camera-canvas-stat-group {
    padding: 5px;
  }

  .camera-canvas-stat-title {
    font-family: @font-regular;
    font-size: @font-size-small;
    line-height: @font-size-small;
    margin-bottom: 0px;
    text-transform: uppercase;
    opacity: 0.7;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .camera-canvas-stat-row {
    font-size: @font-size-small;
    line-height: @font-size-small;
    margin-left: 10px;
    margin-bottom: 0px;
  }
}

.analytics_regions {
  min-height: 28px;
  // margin-bottom: 7px !important;

  .region-tag {
    position: relative !important;
    background: @hover-hightlight;
    transition: 0.3s;

    padding: 1px 4px;
    margin: 1px 5px !important;
    font-size: 13px !important;
    display: inline-block;

    &[data-input='true'] {
      background: fade(@hover-hightlight, 20%);
    }

    .rs-icon-circle {
      margin: 3px 3px;
      font-size: 16px;
      line-height: 22px;
      text-shadow: 0 0 2px @B700;
    }

    .rs-icon-close,
    .rs-icon-check {
      height: 16px;
      width: 16px;
      top: 0;
      margin: 3px 2px;
      text-align: center;
      line-height: 22px;
      cursor: pointer;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .rs-icon-close:hover {
      color: @error-color;
    }

    .rs-icon-check:hover {
      color: @success-color;
    }

    .rs-tag-text {
      display: grid;
      grid-template-columns: min-content auto min-content;
    }

    span.region-tag-input {
      position: relative;
      top: -1px;
      padding: 0 4px;
      border: none;
      min-width: 30px;
      line-height: 23px;
      display: inline-block !important;
      background: none;
      cursor: pointer;
    }

    input.region-tag-input {
      position: relative;
      top: -1px;
      padding: 0 4px;
      border: none;
      min-width: 40px;
      max-width: 100px;
      line-height: 23px;
      display: inline-block !important;
      background: none;
      font-size: 13px;
    }
  }
}

.camera-error {
  opacity: 0.85 !important;
  // position: absolute;
  // top: 100%;
  width: 100%;

  & > .rs-message-container {
    padding: 8px 8px 8px 30px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > .rs-message-icon-wrapper {
      top: 10px;
      left: 12px;
    }
  }
}

.camera-info {}

.frames-heartbeat {
  position: absolute;
  top: 16px;
  left: 22px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  transition: 0.1s;
  background: @success-color;
  opacity: 0;

  &[data-tick='true'] {
    opacity: 1;
  }

  &[data-tick='false'] {
    opacity: 0;
  }
}

.camera-view_menu {
  .rs-dropdown-item {
    .rs-dropdown-item-content {
      padding: 0 10px 5px;
    }
  }

  .view-menu_hide {
    color: @B300 !important;
    opacity: 0.6 !important;
  }

  .rs-divider-horizontal {
    margin: 5px;
  }
}

.cameras-regioncolor {
  position: absolute !important;
  bottom: 35px;
  margin-left: -74px;
  z-index: 10;
  background: @body-bg !important;

  input {
    padding: 15px 12px !important;
    font-size: @font-size-base !important;
    box-shadow: none !important;
    // color: @B200 !important;
  }

  & > div:first-child {
    top: auto !important;
    bottom: -10px !important;
    border-width: 10px 10px 0 !important;
    border-color: @body-bg transparent transparent !important;
  }
}

.ptz {
  position: absolute;
  top: 50%;
  right: 30px;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  & > .ptz-btn {
    position: absolute;
    opacity: 0.5;
    font-size: 30px;
  }

  & > .ptz-btn:hover {
    opacity: 1;
  }

  & > .ptz-up {
    right: 30px;
    bottom: 25px;
  }

  & > .ptz-down {
    right: 30px;
    bottom: -25px;
  }

  & > .ptz-left {
    right: 55px;
    bottom: 0px;
  }

  & > .ptz-right {
    right: 5px;
    bottom: 0px;
  }

  .ptz-zoom {
    position: absolute;
    height: 70px;
    top: -55px;
  }
}

.textbox {
  position: absolute;
  background: @base-bg-color;
  display: block;
  color: white;
  padding: 5px;

  white-space: pre;
  line-height: 1.1;
  width: auto;
}

.cameras-inlineprogress {
  display: grid;
  grid-template-columns: 120px auto;
  line-height: 40px;
}

.camera-smartmjpeg_label {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 10px;
  opacity: 0.7;
}

.smartvideo-hidden {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}

.smartvideo-cacheprogress {
  position: absolute;
  bottom: -6px;
  width: 100%;
  pointer-events: none;
  opacity: .7;
}


.cameras-founded {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.hls-player {
  width: 100%;
  height: auto !important;
}

.hls-player video {
  display: block;
  width: 100%;
  border-radius: 6px;
}

.vjs-hidden,
.vjs-control-bar,
.vjs-loading-spinner,
.vjs-big-play-button,
.vjs-text-track-display,
.vjs-modal-dialog {
  display: none;
}
