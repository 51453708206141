@import 'theme.less';

.archive-filename {
  width: 160px;
}

.archive-tick {
  position: absolute;
  bottom: 0;
  margin-left: -5px;
}

.archive-timeline-ticks {
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  bottom: 0;

  // height: 1px;
  // background-color: red;
}

.archive-timeline {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 3px;
  opacity: 0.6;
}

.archive-timeline-file {
  position: absolute;
  height: 12px;
  min-width: 6px;
  background: rgba(200, 200, 200, 1);
  transition: 0.2s;

  &.round-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.round-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  &[data-selected='true'] {
    background: @base-color;
    height: 20px;
    top: -2px;
    z-index: 999;
  }
}

.hoursrange-container {
  width: 35vw;
  min-width: 300px;
  padding: 20px;
}

.hoursrange-btn {
  min-width: 84px;
  text-align: left;
  border-radius: 34px;
  background: @panel-bg-color;
  height: 34px;

  & > .rs-icon,
  & > svg {
    margin-right: 5px;
    opacity: 0.5;
  }
}

.archive-events {
  position: absolute;
  width: 100%;
  height: 0;
  top: 24px;
  padding: 0 15px;
  opacity: 0.8;
}

.archive-event {
  position: absolute;
  top: -24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  min-width: 3px;
  transition: 0.1s;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
}

.archive-crop-slider {
  padding: 10px 15px 10px 5px;
  position: relative;
  // padding: 10px 50px;
  // width: calc(100% - 10px);
}

.archive-video-controls {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px 6px;

  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-gap: 15px;
}

.archive-slider {
  padding: 9px;
  left: 5px;
  width: calc(100% - 10px);

  .rs-slider-handle {
    top: 0;

    &::before {
      display: none;
    }
  }
}

.archive-slider-handler {
  position: absolute;
  z-index: 1;

  top: 3px;
  left: -17px;
  width: 35px;
  text-align: center;
  border-radius: @default-border-radius;
  cursor: default;
  padding: 1px 4px;
  background: @base-color;
  font-size: 11px;
  // font-family: @font-monospace;
}

.videoarchive-camera {
  &[data-expand='true'] {
    .camera-player {
      width: 100%;
    }
  }
}

.archive-drawandcut-progress {
  display: grid;
  grid-template-columns: auto max-content max-content;
  gap: 10px;

  & + .archive-drawandcut-progress {
    margin-top: 10px;
  }
}
