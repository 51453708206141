@media (max-width: 1200px) {
  .center-panel {
    width: 100%;
  }
  .content {
    padding: 10px 5px;
  }
  .content-panel {
    margin: 5px 0;
  }
}

