@import 'theme.less';

.toolbar {
  border-color: @input-border-color !important;

  .rs-panel-body {
    position: relative;
    display: grid;
    grid-template-columns: auto minmax(min-content, max-content) auto;
    gap: 5px;

    .rs-btn-toolbar:first-child {
      margin: 1px 2px 0 10px;
    }
  }

  .rs-btn-toolbar {
    line-height: 30px;

    &[align='right'] {
      white-space: nowrap;
    }

    &[align='center'] {
      overflow: hidden;
    }
  }

  .scm-menubtn_img {
    width: 14px;
    max-height: 14px;
    filter: brightness(0) invert(1);
  }

  .rs-btn-icon {
    width: 36px;
    padding-left: 11px;
  }
}
