@import 'theme.less';

.duration-picker {
  min-width: 200px;
  width: 30vw;
  padding: 0 10px;
}

.duration-picker_row {
  display: grid;
  grid-template-columns: auto 50px;
  grid-gap: 30px;
  padding: 10px;

  .rs-slider-bar {
    margin-top: 7px;
  }

  .rs-slider-handle::before {
    display: none;
  }
}

.duration-slider_handler {
  position: absolute;
  margin-left: -20px;
  top: 8px;

  .rs-tag {
    cursor: pointer;
    width: 40px;
    text-align: center;
  }
}

.chart_nodata {
  text-align: center;

  .spinner {
    top: calc(50% - 15px);
    margin: 0 auto;
  }
}

.chart-btns {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.chart-fontsize {
  position: absolute !important;
  left: 5px;
  bottom: 5px;
  cursor: default;
}

.chart-cast_script-example {
  margin-top: 5px;

  pre {
    font-size: @font-size-extra-small - 1;
    background-color: @input-bg;
    padding: 5px;
    margin: 0;
    border-radius: @default-border-radius;
    line-height: @font-size-extra-small + 1;
  }
}

.chart-colors {
  position: relative;
  padding: 0 5px;
  top: 1px;
  line-height: 30px;
  min-height: 31.5px;
  margin: 0 !important;
}

.chart-color {
  padding: 0 3px;
  font-size: 24px;
  margin-bottom: 2px;
}

.chart-label_color {
  position: relative;
  padding: 1px 6px 0 2px;
  margin-left: 4px !important;
  top: 2px;
  left: -5px;

  &[data-edit='false'] {
    cursor: pointer;
  }

  .rs-tag-text {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 4px;
    padding: 0 0 0 4px;
  }

  .rs-icon {
    position: relative;
    font-size: 16px;
    margin-top: 3px;
    cursor: pointer;
  }

  .tag-input {
    line-height: 24px;
    padding: 0 2px;
  }

  .icon-close {
    font-size: 14px;
    opacity: .6;
    margin-top: 5px;

    &:hover {
      color: @error-color;
    }
  }

  .icon-check {
    font-size: 14px;
    opacity: .6;
    margin-top: 5px;

    &:hover {
      color: @success-color;
    }
  }

}

//
// table
//

.chart-table-container {
  position: relative;
  width: 100%;
  max-height: 245px;
}

.chart-table {
  width: 100%;

  .chart-table_link {
    cursor: pointer;
    padding: 2px;
    transition: .2s;

    &:hover {
      color: @base-color;
    }
  }

  .chart-table-tr {
    border-bottom: 1px solid @table-border-color;
  }

  .chart-table_th {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 3px;
    font-size: 80%;
    min-width: 65px;
  }

  .chart-table-td {
    text-align: center;
    padding: 8px 3px;
  }

  .chart-table-tdlabel {
    font-weight: bold;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chart-radio-type {
  .rs-radio-checker {
    & > label {
      min-width: 50px;
      text-align: center;

      .rs-icon {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.rs-panel {
  .chart {
    margin: -5px;
  }
}
